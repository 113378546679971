.MuiButton-root
  box-shadow: none
  font-size: 16px
  border-radius: 8px
  min-height: 44px
  min-width: 90px
  text-transform: none

  .MuiTouchRipple-root
    display: none

  .MuiCircularProgress-root
    height: 20px !important
    width: 20px !important