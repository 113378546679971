@font-face {
    font-family: 'gilroy-extrabold';
    src: url('gilroy-extrabold-webfont.woff2') format('woff2'),
         url('gilroy-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gilroy-medium';
    src: url('gilroy-medium-webfont.woff2') format('woff2'),
         url('gilroy-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gilroy-semibold';
    src: url('gilroy-semibold-webfont.woff2') format('woff2'),
         url('gilroy-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}