html, body
  background-color: #FBFBFB !important
  padding: 0
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

a
  color: inherit

*
  box-sizing: border-box

img
  height: auto
  max-width: 100%

.asklog-theme
  @import elements/button